import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { tap } from "rxjs/operators";
import { Router } from "@angular/router";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  /**
   * Identifies and handles a given HTTP request
   * @param request — The outgoing request object to handle
   * @param next — The next interceptor in the chain, or the backend if no interceptors remain in the chain
   * @returns — An observable of the event stream
   */
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const user = localStorage.getItem("x-t-security");
    const isLoggedIn = user && user;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${user}`,
        },
      });
    }
    return next.handle(request).pipe(
      tap(
        (): void => {},
        (err: HttpErrorResponse): void => {
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              return;
            }
            localStorage.removeItem("x-t-security");
            localStorage.removeItem("editConference");
            localStorage.removeItem("user");
            this.router.navigate(["/login"]);
          }
        }
      )
    );
  }
}
