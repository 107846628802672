import { Component, OnInit } from "@angular/core";
import { AdminService } from "./admin.service";
import { Router } from "@angular/router";
import { SecurityService } from "../security.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-admin",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"],
  providers: [AdminService, SecurityService],
})
export class AdminComponent implements OnInit {
  isIos = false;
  startTime = { hour: 12, minute: 30 };
  endTime = { hour: 13, minute: 30 };
  seminarDates: any;
  evaluationDates: any;
  disableSend = false;
  title: any;
  ort: any;
  seminar_number: any;
  start_time: any;
  end_time: any;
  send_to: any;
  dateFrom: any;
  dateEnd: any;
  dateEvaluationFrom: any;
  dateEvaluationEnd: any;
  errorSending = false;
  successSending = false;
  MAX_WORKSHOPS = 99;
  totalWorkshopsReached = false;
  ErrorMessage: string;
  konferenz = false;
  inValidWorkshop = false;

  public workshops: any[] = [
    {
      workshop: "",
    },
  ];
  constructor(
    private securityService: SecurityService,
    private fb: FormBuilder,
    private adminService: AdminService,
    private router: Router
  ) {}
  getDates($event) {
    this.seminarDates = { from: $event.from, to: $event.to };
  }
  getEvaluationDates($event) {
    this.evaluationDates = { from: $event.from, to: $event.to };
  }
  ngOnInit() {
    let userAgent = window.navigator.userAgent;
    if (
      localStorage.getItem("x-t-security") == undefined ||
      localStorage.getItem("user") == undefined
    ) {
      this.logout();
    }
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/android/i)
    ) {
      this.isIos = true;
    }
  }
  omit_special_char(event) {
    var k;
    k = event.charCode;
    return k !== 124;
  }
  logout() {
    localStorage.removeItem("x-t-security");
    localStorage.removeItem("user");
    this.router.navigate(["/login"]);
  }
  reload() {
    window.location.reload();
  }
  toConferences() {
    this.router.navigate(["/conferences"]);
  }
  changeDateFrom($event) {
    if (this.dateFrom != undefined && this.dateEnd != undefined) {
      let dateFrom = new Date(this.dateFrom.replace(/-/g, "/"));
      let dateEnd = new Date(this.dateEnd.replace(/-/g, "/"));
      if (dateFrom.getTime() > dateEnd.getTime()) {
        this.dateFrom = this.dateEnd;
      }
    }
  }
  changeDateEnd($event) {
    if (this.dateFrom != undefined && this.dateEnd != undefined) {
      let dateFrom = new Date(this.dateFrom.replace(/-/g, "/"));
      let dateEnd = new Date(this.dateEnd.replace(/-/g, "/"));
      if (dateFrom.getTime() > dateEnd.getTime()) {
        this.dateEnd = this.dateFrom;
      }
    }
  }

  changeDateEvaluationFrom($event) {
    if (
      this.dateEvaluationFrom != undefined &&
      this.dateEvaluationEnd != undefined
    ) {
      let dateFrom = new Date(this.dateEvaluationFrom.replace(/-/g, "/"));
      let dateEnd = new Date(this.dateEvaluationEnd.replace(/-/g, "/"));
      if (dateFrom.getTime() > dateEnd.getTime()) {
        this.dateEvaluationFrom = this.dateEvaluationEnd;
      }
    }
  }
  changeDateEvaluationEnd($event) {
    if (
      this.dateEvaluationFrom != undefined &&
      this.dateEvaluationEnd != undefined
    ) {
      let dateFrom = new Date(this.dateEvaluationFrom.replace(/-/g, "/"));
      let dateEnd = new Date(this.dateEvaluationEnd.replace(/-/g, "/"));
      if (dateFrom.getTime() > dateEnd.getTime()) {
        this.dateEvaluationEnd = this.dateEvaluationFrom;
      }
    }
  }
  addWorkshop() {
    if (this.workshops.length >= this.MAX_WORKSHOPS) {
      this.totalWorkshopsReached = true;
    } else {
      this.workshops.push({
        workshop: "",
      });
    }
  }
  rectifyTime(time) {
    if (time.length === 1) {
      return "0" + time;
    }
    return time;
  }

  removeWorkshop(i: number) {
    this.workshops.splice(i, 1);
  }
  saveCourse(
    title,
    ort,
    Seminarnummer,
    workshops,
    startTime,
    endTime,
    sendTo,
    konferenz
  ) {
    this.errorSending = false;
    this.disableSend = true;
    this.title = title;
    this.ort = ort;
    this.seminar_number = Seminarnummer;
    this.workshops = workshops;
    this.start_time = startTime;
    this.end_time = endTime;
    this.send_to = sendTo;
    this.konferenz = konferenz;
    if (this.isIos === true) {
      let dateFrom = this.dateFrom.split("-");
      let dateEnd = this.dateEnd.split("-");
      this.seminarDates = {
        from: { day: dateFrom[2], month: dateFrom[1], year: dateFrom[0] },
        to: { day: dateEnd[2], month: dateEnd[1], year: dateEnd[0] },
      };
      let dateEvaluationFrom = this.dateEvaluationFrom.split("-");
      let dateEvaluationEnd = this.dateEvaluationEnd.split("-");
      this.evaluationDates = {
        from: {
          day: dateEvaluationFrom[2],
          month: dateEvaluationFrom[1],
          year: dateEvaluationFrom[0],
        },
        to: {
          day: dateEvaluationEnd[2],
          month: dateEvaluationEnd[1],
          year: dateEvaluationEnd[0],
        },
      };
    }
    this.start_time.hour = this.start_time.hour.toString();
    this.start_time.minute = this.start_time.minute.toString();
    this.end_time.hour = this.end_time.hour.toString();
    this.end_time.minute = this.end_time.minute.toString();
    this.start_time.hour = this.rectifyTime(this.start_time.hour);
    this.start_time.minute = this.rectifyTime(this.start_time.minute);
    this.end_time.hour = this.rectifyTime(this.end_time.hour);
    this.end_time.minute = this.rectifyTime(this.end_time.minute);
    const data = {
      title: this.title,
      ort: this.ort,
      created_by: localStorage.getItem("user"),
      seminar_number: this.seminar_number,
      seminar_dates: this.seminarDates,
      workshops: this.workshops,
      evaluation_dates: this.evaluationDates,
      start_time: this.start_time,
      end_time: this.endTime,
      send_to: this.send_to,
      online: this.konferenz ? 1 : 0,
    };
    let invalid = this.workshops.filter(({ workshop }) => workshop === '').length;
    if(invalid === 0){
      this.inValidWorkshop = false
    } else{
      this.inValidWorkshop = true;
    }

    if (
      !data.seminar_number ||
      !data.title ||
      !data.ort ||
      !data.seminar_dates ||
      !data.evaluation_dates ||
      !data.start_time ||
      !data.end_time ||
      !data.send_to ||
      this.inValidWorkshop
    ) {
      console.log(this.workshops, "first");
      this.ErrorMessage = "falsche Angaben";
      this.disableSend = false;
      this.errorSending = true;
      return;
    } else {
      this.adminService.create_conference(data).subscribe(
        (res) => {
          this.disableSend = false;
          this.successSending = true;
        },
        (err) => {
          if (err.status === 300) {
            this.ErrorMessage = "Diese Seminarnummer existiert bereits";
          } else {
            this.ErrorMessage = "falsche Angaben";
          }
          this.disableSend = false;
          this.errorSending = true;
        }
      );
    }

    // this.adminService.create_conference(data).subscribe(
    //   (res) => {
    //     this.disableSend = false;
    //     this.successSending = true;
    //   },
    //   (err) => {
    //     if (err.status === 300) {
    //       this.ErrorMessage = "Diese Seminarnummer existiert bereits";
    //     } else {
    //       this.ErrorMessage = "falsche Angaben";
    //     }
    //     this.disableSend = false;
    //     this.errorSending = true;
    //   }
    // );
    //  else {
    //   this.ErrorMessage = "falsche Angaben";
    //   this.disableSend = false;
    //   this.errorSending = true;
    // }
  }
}
