<div class="input-daterange datepicker row align-items-center">
  <div class="col">
    <div class="form-group" [ngClass]="{ focused: focus3 === true }">
      <div class="input-group" [ngStyle]="{border:(errorSending && !model1) ? '1px solid red' : null}">
        <div class="input-group-prepend">
          <span class="input-group-text" [ngStyle]="{border:(errorSending && !model1) ? 'none' : null}"><i
              class="ni ni-calendar-grid-58"></i></span>
        </div>
        <input class="form-control datepicker" placeholder="Start" name="dp1" [(ngModel)]="model1"
          [ngStyle]="{border:(errorSending && !model1) ? 'none' : null}" style="font-size: 16px;" ngbDatepicker
          #d1="ngbDatepicker" (click)="d1.toggle()" type="text" [dayTemplate]="t"
          (dateSelect)="startDateChanged($event)" autocomplete="off" (focus)="focus3 = true" (blur)="focus3 = false" />
        <ng-template #t let-date let-focused="focused">
          <span class="custom-day" [class.text-white]="isActive(date)" [class.range-end]="isRangeEnd(date)"
            [class.range-start]="isRangeStart(date)" [class.btn-light]="isActive(date)"
            [class.bg-primary]="isActive(date)" [class.range]="isInRange(date)" [class.faded]="isInRange(date)">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>
  <div class="col">
    <div class="form-group" [ngClass]="{ focused: focus4 === true }">
      <div class="input-group" [ngStyle]="{border:(errorSending && !model2) ? '1px solid red' : null}">
        <div class="input-group-prepend">
          <span class="input-group-text" [ngStyle]="{border:(errorSending && !model2) ? 'none' : null}"><i
              class="ni ni-calendar-grid-58"></i></span>
        </div>
        <input class="form-control datepicker" placeholder="Ende" style="font-size: 16px;" name="dp2"
          [(ngModel)]="model2" ngbDatepicker #d2="ngbDatepicker" (click)="d2.toggle()" (focus)="focus4 = true"
          [ngStyle]="{border:(errorSending && !model2) ? 'none' : null}" (blur)="focus4 = false" type="text"
          [dayTemplate]="t1" (dateSelect)="endDateChanged($event)" autocomplete="off" [minDate]="startDate" />
        <ng-template #t1 let-date let-focused="focused" let-disabled="disabled">
          <span class="custom-day" [class.text-white]="isActive(date)" [class.range-end]="isRangeEnd(date)"
            [class.range-start]="isRangeStart(date)" [class.btn-light]="isActive(date)"
            [class.bg-primary]="isActive(date)" [class.range]="isInRange(date)" [class.faded]="isInRange(date)"
            [class.text-muted]="disabled">
            {{ date.day }}
          </span>
        </ng-template>
      </div>
    </div>
  </div>
</div>