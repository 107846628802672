import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class EditConferenceService {
  constructor(private http: HttpClient) {}
  token = localStorage.getItem('x-t-security');
  
  editConference(data): Observable<any> {
    const headers = new HttpHeaders({ Authorization: "Bearer " + this.token });
    const httpOptions = {
      headers: headers
    };
    return this.http.patch<any>(
      environment.apiUrl + "conference/edit",
      data,
      httpOptions
    );
  }
  getConference(conferenceId): Observable<any> {
    const headers = new HttpHeaders({ Authorization: "Bearer " + this.token });
    const httpOptions = {
      headers: headers
    };
    return this.http.get<any>(
      environment.apiUrl + "conference?seminar_number=" + conferenceId,
      httpOptions
    );
  }
}
