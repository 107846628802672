import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class AdminService {
  constructor(private http: HttpClient) {}
  create_conference(data): Observable<any> {
    const token = localStorage.getItem('x-t-security');
    const headers = new HttpHeaders({ Authorization: "Bearer " + token });
    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(
      environment.apiUrl + "conference/add",
      data,
      httpOptions
    );
  }
}
