<main class="profile-page">
  <section class="section-profile-cover section-shaped my-0">
    <!-- Circles background -->
    <div class="shape shape-style-1 shape-primary alpha-4">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </section>
  <section class="section">
    <div class="container">
      <div class="card card-profile shadow mt--300">
        <div class="px-4">
          <div class="row">
            <div class="col-6 card-profile-actions py-4 mt-lg-0">
              <a
                href="javascript:void(0)"
                class="btn btn-sm btn-info mr-4"
                (click)="logout()"
                >Abmelden</a
              >
            </div>
            <div class="col-6 order-lg-3 text-lg-right align-self-lg-center">
              <div class="card-profile-actions py-4 mt-lg-0">
                <a 
                href="javascript:void(0)" 
                class="btn btn-sm btn-default float-right"
                style="background-color:rgb(23, 43, 77); color: white;"
                (click)="createConference()"
                >Neue Veranstaltung</a>
              </div>
            </div>
          </div>
          <div class="text-center mt-5">
            <h3 class="headline">Meine Veranstaltungen</h3>
            <div class="h6 font-weight-300"></div>
          </div>
              <div class="container mt-4" style="margin-bottom: 100px;">
          <div class="card">
                       <div class="card-body" *ngIf="conferences.length != 0">
                           <table class="table table-hover table-responsive mb-0">
                               <thead>
                                   <tr>
                                       <th class="th-lg"><a><em class="fa fa-cog"></em> Aktion</a></th>
                                       <th class="th-lg"><a>Seminarnummer</a></th>
                                       <th class="th-lg"><a>Veranstaltungstitel</a></th>
                                       <th class="th-lg"><a>Ort</a></th>
                                       <th class="th-lg"><a>Startdatum</a></th>
                                       <th class="th-lg"><a>Enddatum</a></th>
                                       <th class="th-lg"><a>Evaluationsstart</a></th>
                                       <th class="th-lg"><a>Evaluationsende</a></th>
                                       <th class="th-lg"><a>Auswertung an</a></th>
                                       <th class="th-lg"><a>Erstellt am</a></th>
                                   </tr>
                               </thead>
                               <tbody>
                                   <tr *ngFor="let conference of conferences">
                                      <td>
                                        <button 
                                            class="btn btn-sm btn-primary mb-1"
                                            style="width: 96px !important;"
                                            type="button"
                                            (click)="open(classic2, 'Notification', '', conference.seminar_number, 'edit')">
                                          <i class="fa fa-pencil" aria-hidden="true"></i>
                                          Bearbeiten
                                        </button>
                                        <button
                                          class="btn btn-sm btn-warning"
                                          type="button"
                                          (click)="open(classic2, 'Notification', '', conference.seminar_number, 'delete')">
                                          <i class="fa fa-trash-o mr-3" aria-hidden="true"></i>
                                          Löschen
                                        </button>
                                      </td>
                                       <td>{{conference.seminar_number}}</td>
                                       <td>{{conference.title}}</td>
                                       <td>{{conference.ort}}</td>
                                       <td>{{conference.start}}</td>
                                       <td>{{conference.end}}</td>
                                       <td>{{conference.start_evaluation}} {{conference.start_time}}</td>
                                       <td>{{conference.end_evaluation}} {{conference.end_time}}</td>
                                       <td>{{conference.send_to}}</td>
                                       <td>{{conference.created_at | date:'yyyy.MM.dd HH.MM'}}</td>
                                   </tr>
                               </tbody>
                           </table>
                           <div class="d-flex justify-content-center">
                               <nav class="my-4 pt-2">
                                   <ul class="pagination pagination-circle pg-blue mb-0">
                                    <li class="page-item clearfix d-none d-md-block" [class.disabled]="actualPage === 1"><a class="page-link" (click)="getNewPage(1, 'first')">&laquo;</a></li>
                                       <li class="page-item" [class.disabled]="actualPage === 1">
                                           <a class="page-link" aria-label="Previous" (click)="getNewPage(actualPage-1, 'desc')">
                                                   <span aria-hidden="true">&#10216;</span>
                                               </a>
                                       </li>
                                       <div *ngFor="let page of totalPages">
                                        <li class="page-item" [class.active]="actualPage === (page + 1)"><a class="page-link" (click)="getNewPage(page+1)">{{ page + 1 }}</a></li>
                                      </div>
                                       <li class="page-item" [class.disabled]="actualPage === numberMaxPages">
                                           <a class="page-link" aria-label="Next" (click)="getNewPage(actualPage+1, 'asc')">
                                                   <span aria-hidden="true"> &#10217;</span>
                                               </a>
                                       </li>
                                       <li class="page-item clearfix d-none d-md-block" [class.disabled]="actualPage === numberMaxPages"><a class="page-link" (click)="getNewPage(numberMaxPages, 'last')">&raquo;</a></li>
                                   </ul>
                               </nav>
                           </div>
                       </div>
                       <div class="card-body" *ngIf="conferences.length === 0">
                          <h6>
                            Keine Veranstaltungen
                          </h6>
                       </div>
                   </div>
              </div>
        </div>
      </div>
    </div>
  </section>
</main>
<div class="col-md-4">
  <ng-template #classic2 let-c="close" let-d="dismiss">
    <div class="modal-content" [ngClass]="(action === EDIT)?'bg-gradient-info':'bg-gradient-sucess'">
      <div class="modal-header">
        <h6 class="modal-title" id="modal-title-notification">Deine Aufmerksamkeit ist erforderlich</h6>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="d('Cross click')">
          <span aria-hidden="true">×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="py-3 text-center">
          <i class="ni ni-bell-55 ni-3x"></i>
          <p *ngIf="action===EDIT">Du bist dabei, die Veranstaltung {{ selectedConference }} zu bearbeiten</p>
          <p *ngIf="action===DELETE">Du bist dabei, die Veranstaltung {{ selectedConference }} zu löschen</p>
          <p *ngIf="action===''">Diese Veranstaltung wurde bereits evaluiert und kann nicht bearbeitet werden.</p>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-link text-white" (click)="d('Cross click')">Abbrechen</button>
        <button type="button" *ngIf="action!==''" class="btn btn-white ml-auto" data-dismiss="modal" (click)="c('Close click')">Weiter</button>
      </div>
    </div>
  </ng-template>
</div>
