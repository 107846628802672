import { environment } from "../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class ConferencesService {
  constructor(private http: HttpClient) {}
  token = localStorage.getItem("x-t-security");

  getConferences(user, page): Observable<any> {
    const headers = new HttpHeaders({ Authorization: "Bearer " + this.token });
    const httpOptions = {
      headers: headers,
    };
    return this.http.get<any>(
      environment.apiUrl + "conference/" + user + "/" + page,
      httpOptions
    );
  }
  deleteConferenceByNumber(conferenceId): Observable<any> {
    const headers = new HttpHeaders({ Authorization: "Bearer " + this.token });
    const httpOptions = {
      headers: headers,
    };
    return this.http.delete<any>(
      environment.apiUrl + `conference/delete?seminar_number=${conferenceId}`,
      httpOptions
    );
  }
  
  isEditable(conferenceId): Observable<any> {
    const headers = new HttpHeaders({ Authorization: "Bearer " + this.token });
    const httpOptions = {
      headers: headers,
    };
    return this.http.get<any>(
      environment.apiUrl +
        `conference/is-editable?seminar_number=${conferenceId}`,
      httpOptions
    );
  }
}
