import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class LoginService {
  constructor(private http: HttpClient) {}
  signin(data): Observable<any> {
    const token = environment.token;
    const headers = new HttpHeaders({ Authorization: "Bearer " + token });
    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(environment.apiUrl + "auth/login", data, httpOptions);
  }
}
