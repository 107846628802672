import { TopicModel } from "./topic.model";
import { environment } from "./../../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class VerdiService {
  constructor(private http: HttpClient) {}
  token = localStorage.getItem('x-t-security');

  getQuestionnaire(q_id): Observable<TopicModel[]> {
    const headers = new HttpHeaders({ Authorization: "Bearer " + this.token });
    const httpOptions = {
      headers: headers
    };
    return this.http.get<TopicModel[]>(
      environment.apiUrl + "questionnaire/" + q_id,
      httpOptions
    );
  }
  sendQuestionnaireAnswers(data): Observable<any> {
    const headers = new HttpHeaders({ Authorization: "Bearer " + this.token });
    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(
      environment.apiUrl + "answer",
      data,
      httpOptions
    );
  }
}
