import { Component, OnInit } from "@angular/core";
import { LoginService } from "./login.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
  providers: [LoginService],
})
export class LoginComponent implements OnInit {
  focus;
  focus1;
  email = "";
  password = "";
  disableButton = false;
  showError = false;
  constructor(private loginService: LoginService, private router: Router) {}

  ngOnInit() {
    if (
      localStorage.getItem("x-t-security") !== null &&
      localStorage.getItem("x-t-security") !== null
    ) {
      // more logic here servoce to get all tokens
      this.router.navigate(["/conferences"]);
    }
  }

  login(email, password) {
    this.showError = false;
    this.disableButton = true;
    const data = {
      mail: email,
      password: password,
    };
    this.loginService.signin(data).subscribe(
      (res) => {
        localStorage.setItem("x-t-security", res.data.tokens);
        localStorage.setItem("user", this.email);
        this.router.navigate(["/conferences"]);
      },
      (err) => {
        this.disableButton = false;
        this.showError = true;
      }
    );
  }
}
