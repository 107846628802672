import { environment } from "../environments/environment";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class SecurityService {
  constructor(private http: HttpClient) {}
  verifyUser(data): Observable<any> {
    const token = environment.token;
    const headers = new HttpHeaders({ Authorization: "Bearer " + token });
    const httpOptions = {
      headers: headers
    };
    return this.http.post<any>(
      environment.apiUrl + "verify-user",
      data,
      httpOptions,
    );
  }
}
