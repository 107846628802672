<nav
  id="navbar-main"
  class="navbar navbar-main navbar-expand-lg navbar-transparent navbar-light headroom headroom--top headroom--pinned"
>
  <div class="container">
    <a class="navbar-brand mr-lg-5" [routerLink]="['/']">
      <img src="./assets/img/ill/logo_verdi_bub.svg" />
    </a>
    <div
      class="navbar-collapse collapse"
      id="navbar_global"
      [ngbCollapse]="isCollapsed"
    >
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a [routerLink]="['/']"> </a>
          </div>
          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              (click)="isCollapsed = !isCollapsed"
              [attr.aria-expanded]="!isCollapsed"
              aria-controls="navbar_global"
            >
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav navbar-nav-hover align-items-lg-center"></ul>
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            target="_blank"
            data-toggle="tooltip"
          >
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            target="_blank"
            data-toggle="tooltip"
            title="Follow us on Instagram"
          >
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            target="_blank"
            data-toggle="tooltip"
            title="Follow us on Twitter"
          >
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link nav-link-icon"
            target="_blank"
            data-toggle="tooltip"
            title="Star us on Github"
          >
          </a>
        </li>
        <li class="nav-item d-none d-lg-block ml-lg-4">
          <div style="color:#000;">
            <span class="nav-link-inner--text">Wissen bewegt </span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
