import { Component, OnInit } from "@angular/core";
import { EditConferenceService } from "./edit-conference.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-admin",
  templateUrl: "./edit-conference.component.html",
  styleUrls: ["./edit-conference.component.scss"],
  providers: [EditConferenceService],
})
export class EditConferenceComponent implements OnInit {
  isIos = false;
  startTime = { hour: 12, minute: 30 };
  endTime = { hour: 13, minute: 30 };
  seminarDates: any;
  evaluationDates: any;
  disableSend = false;
  title: any;
  ort: any;
  seminar_number: any;
  start_time: any;
  end_time: any;
  send_to: any;
  dateFrom: any;
  dateEnd: any;
  dateEvaluationFrom: any;
  dateEvaluationEnd: any;
  errorSending = false;
  MAX_WORKSHOPS = 99;
  totalWorkshopsReached = false;
  isLoading = true;
  ErrorMessage: string;
  konferenz: any;
  inValidWorkshop = false;
  public workshops: any[] = [
    {
      workshop: "",
    },
  ];
  constructor(
    private editConferenceService: EditConferenceService,
    private router: Router
  ) {}
  getDates($event) {
    this.seminarDates = { from: $event.from, to: $event.to };
  }
  getEvaluationDates($event) {
    this.evaluationDates = { from: $event.from, to: $event.to };
  }
  cleanTime(time, index) {
    return Number(time.split(":")[index]);
  }
  cleanPhoneDates(date) {
    let dateArray = date.split(".");
    return dateArray[2] + "-" + dateArray[1] + "-" + dateArray[0];
  }
  ngOnInit() {
    let userAgent = window.navigator.userAgent;
    if (
      localStorage.getItem("x-t-security") == undefined ||
      localStorage.getItem("user") == undefined
    ) {
      this.logout();
    }
    if (
      userAgent.match(/iPad/i) ||
      userAgent.match(/iPhone/i) ||
      userAgent.match(/android/i)
    ) {
      this.isIos = true;
    }
    this.editConferenceService
      .getConference(localStorage.getItem("editConference"))
      .subscribe(
        (res) => {
          this.seminar_number = res.data.seminar_number;
          this.title = res.data.title;
          this.ort = res.data.ort;
          this.send_to = res.data.send_to;
          this.konferenz = res.data.online == 1 ? true : false;

          this.startTime = {
            hour: this.cleanTime(res.data.start_time, 0),
            minute: this.cleanTime(res.data.start_time, 1),
          };
          this.endTime = {
            hour: this.cleanTime(res.data.end_time, 0),
            minute: this.cleanTime(res.data.end_time, 1),
          };
          let dateEnd = res.data.end.split("-");
          let dateFrom = res.data.start.split("-");
          this.seminarDates = {
            from: {
              day: Number(dateFrom[0]),
              month: Number(dateFrom[1]),
              year: Number(dateFrom[2]),
            },
            to: {
              day: Number(dateEnd[0]),
              month: Number(dateEnd[1]),
              year: Number(dateEnd[2]),
            },
          };
          let evaluationEnd = res.data.end_evaluation.split("-");
          let evaluationFrom = res.data.start_evaluation.split("-");
          this.evaluationDates = {
            from: {
              day: Number(evaluationFrom[0]),
              month: Number(evaluationFrom[1]),
              year: Number(evaluationFrom[2]),
            },
            to: {
              day: Number(evaluationEnd[0]),
              month: Number(evaluationEnd[1]),
              year: Number(evaluationEnd[2]),
            },
          };
          if (this.isIos) {
            this.dateEvaluationFrom = this.cleanPhoneDates(
              res.data.start_evaluation
            );
            this.dateEvaluationEnd = this.cleanPhoneDates(
              res.data.end_evaluation
            );
            this.dateFrom = this.cleanPhoneDates(res.data.start);
            this.dateEnd = this.cleanPhoneDates(res.data.end);
          }
          let wpArray = res.data.workshops.split("|");
          this.workshops = [];
          for (let entry of wpArray) {
            this.workshops.push({
              workshop: entry,
            });
          }
          this.isLoading = false;
        },
        (err) => {}
      );
  }
  omit_special_char(event) {
    var k;
    k = event.charCode;
    return k !== 124;
  }
  logout() {
    localStorage.removeItem("x-t-security");
    localStorage.removeItem("user");
    this.router.navigate(["/login"]);
  }
  toConferences() {
    this.router.navigate(["/conferences"]);
  }
  changeDateFrom($event) {
    if (this.dateFrom != undefined && this.dateEnd != undefined) {
      let dateFrom = new Date(this.dateFrom.replace(/-/g, "/"));
      let dateEnd = new Date(this.dateEnd.replace(/-/g, "/"));
      if (dateFrom.getTime() > dateEnd.getTime()) {
        this.dateFrom = this.dateEnd;
      }
    }
  }
  changeDateEnd($event) {
    if (this.dateFrom != undefined && this.dateEnd != undefined) {
      let dateFrom = new Date(this.dateFrom.replace(/-/g, "/"));
      let dateEnd = new Date(this.dateEnd.replace(/-/g, "/"));
      if (dateFrom.getTime() > dateEnd.getTime()) {
        this.dateEnd = this.dateFrom;
      }
    }
  }

  changeDateEvaluationFrom($event) {
    if (
      this.dateEvaluationFrom != undefined &&
      this.dateEvaluationEnd != undefined
    ) {
      let dateFrom = new Date(this.dateEvaluationFrom.replace(/-/g, "/"));
      let dateEnd = new Date(this.dateEvaluationEnd.replace(/-/g, "/"));
      if (dateFrom.getTime() > dateEnd.getTime()) {
        this.dateEvaluationFrom = this.dateEvaluationEnd;
      }
    }
  }
  changeDateEvaluationEnd($event) {
    if (
      this.dateEvaluationFrom != undefined &&
      this.dateEvaluationEnd != undefined
    ) {
      let dateFrom = new Date(this.dateEvaluationFrom.replace(/-/g, "/"));
      let dateEnd = new Date(this.dateEvaluationEnd.replace(/-/g, "/"));
      if (dateFrom.getTime() > dateEnd.getTime()) {
        this.dateEvaluationEnd = this.dateEvaluationFrom;
      }
    }
  }
  addWorkshop() {
    if (this.workshops.length >= this.MAX_WORKSHOPS) {
      this.totalWorkshopsReached = true;
    } else {
      this.workshops.push({
        workshop: "",
      });
    }
  }
  rectifyTime(time) {
    if (time.length === 1) {
      return "0" + time;
    }
    return time;
  }

  removeWorkshop(i: number) {
    this.workshops.splice(i, 1);
  }
  saveCourse(
    title,
    ort,
    Seminarnummer,
    workshops,
    startTime,
    endTime,
    sendTo,
    konferenz
  ) {
    this.errorSending = false;
    this.disableSend = true;
    this.title = title;
    this.ort = ort;
    this.seminar_number = Seminarnummer;
    this.workshops = workshops;
    this.start_time = startTime;
    this.end_time = endTime;
    this.send_to = sendTo;
    this.konferenz = konferenz;
    if (this.isIos === true) {
      let dateFrom = this.dateFrom.split("-");
      let dateEnd = this.dateEnd.split("-");
      this.seminarDates = {
        from: { day: dateFrom[2], month: dateFrom[1], year: dateFrom[0] },
        to: { day: dateEnd[2], month: dateEnd[1], year: dateEnd[0] },
      };
      let dateEvaluationFrom = this.dateEvaluationFrom.split("-");
      let dateEvaluationEnd = this.dateEvaluationEnd.split("-");
      this.evaluationDates = {
        from: {
          day: dateEvaluationFrom[2],
          month: dateEvaluationFrom[1],
          year: dateEvaluationFrom[0],
        },
        to: {
          day: dateEvaluationEnd[2],
          month: dateEvaluationEnd[1],
          year: dateEvaluationEnd[0],
        },
      };
    }
    this.start_time.hour = this.start_time.hour.toString();
    this.start_time.minute = this.start_time.minute.toString();
    this.end_time.hour = this.end_time.hour.toString();
    this.end_time.minute = this.end_time.minute.toString();
    this.start_time.hour = this.rectifyTime(this.start_time.hour);
    this.start_time.minute = this.rectifyTime(this.start_time.minute);
    this.end_time.hour = this.rectifyTime(this.end_time.hour);
    this.end_time.minute = this.rectifyTime(this.end_time.minute);
    const data = {
      title: this.title,
      ort: this.ort,
      created_by: localStorage.getItem("user"),
      seminar_number: this.seminar_number,
      seminar_dates: this.seminarDates,
      workshops: this.workshops,
      evaluation_dates: this.evaluationDates,
      start_time: this.start_time,
      end_time: this.endTime,
      send_to: this.send_to,
      old_seminar_number: localStorage.getItem("editConference"),
      online: konferenz ? 1 : 0,
    };
    let invalid = this.workshops.filter(
      ({ workshop }) => workshop === ""
    ).length;
    if (invalid === 0) {
      this.inValidWorkshop = false;
    } else {
      this.inValidWorkshop = true;
    }
    if (
      !data.seminar_number ||
      !data.title ||
      !data.ort ||
      !data.seminar_dates ||
      !data.evaluation_dates ||
      !data.start_time ||
      !data.end_time ||
      !data.send_to ||
      this.inValidWorkshop
    ) {
      this.ErrorMessage = "falsche Angaben";
      this.disableSend = false;
      this.errorSending = true;
      return;
    } else {
      this.editConferenceService.editConference(data).subscribe(
        (res) => {
          this.disableSend = false;
          this.toConferences();
        },
        (err) => {
          if (err.status === 300) {
            this.ErrorMessage = "Diese Seminarnummer existiert bereits";
          } else {
            this.ErrorMessage = "falsche Angaben";
          }
          this.disableSend = false;
          this.errorSending = true;
        }
      );
    }
  }
}
