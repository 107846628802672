import { Component, OnInit, Input } from "@angular/core";
import { Message } from "@angular/compiler/src/i18n/i18n_ast";

export interface IAlert {
  id: number;
  type: string;
  strong?: string;
  message: string;
  icon?: string;
}

@Component({
  selector: "app-alerts-section",
  templateUrl: "./alerts-section.component.html",
  styleUrls: ["./alerts-section.component.css"]
})
export class AlertsSectionComponent {
  message: string;
  @Input() customMessage: string;
  @Input()
  public alerts: Array<IAlert> = [];
  private backup: Array<IAlert>;
  constructor() {
    
  }
  ngOnInit() {
    if(this.customMessage != undefined){
      this.message = this.customMessage;
    }
    else{
      this.message = "falsche Angaben"
    }
    this.alerts.push({
      id: 4,
      type: "danger",
      strong: "Fehler!",
      message: this.message,
      icon: "ni ni-support-16"
    });
    this.backup = this.alerts.map((alert: IAlert) => Object.assign({}, alert));

  }

  close(alert: IAlert) {
    this.alerts.splice(this.alerts.indexOf(alert), 1);
  }
}
