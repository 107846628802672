<main>
  <section class="section section-shaped section-lg">
    <div class="shape shape-style-1 bg-gradient-default">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
    <div class="container pt-lg-md">
      <div class="row justify-content-center">
        <div class="col-lg-5">
          <div class="card bg-secondary shadow border-0">
            <div class="card-header bg-white">
              <div class="text-center">
                <h6>Eingabe Evaluierung Veranstaltungen</h6>
              </div>
            </div>
            <div class="card-body px-lg-5 py-lg-5">
              <div *ngIf="showError">
                <app-alerts-section></app-alerts-section>
              </div>
              <form role="form" (ngSubmit)="login(email, password)">
                <div
                  class="form-group mb-3"
                  [ngClass]="{ focused: focus === true }"
                >
                  <div class="input-group input-group-alternative">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="ni ni-email-83"></i
                      ></span>
                    </div>
                    <input
                      class="form-control"
                      placeholder="E-Mail-Adresse"
                      type="email"
                      name="email"
                      style="font-size: 16px"
                      [(ngModel)]="email"
                      (focus)="focus = true"
                      (blur)="focus = false"
                    />
                  </div>
                </div>
                <div
                  class="form-group"
                  [ngClass]="{ focused: focus1 === true }"
                >
                  <div class="input-group input-group-alternative">
                    <div class="input-group-prepend">
                      <span class="input-group-text"
                        ><i class="ni ni-lock-circle-open"></i
                      ></span>
                    </div>
                    <input
                      class="form-control"
                      placeholder="Passwort"
                      type="password"
                      name="password"
                      [(ngModel)]="password"
                      (focus)="focus1 = true"
                      (blur)="focus1 = false"
                      style="font-size: 16px"
                    />
                  </div>
                </div>
                <div
                  class="custom-control custom-control-alternative custom-checkbox"
                >
                  <input
                    class="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label class="custom-control-label" for=" customCheckLogin">
                    <span>Angemeldet bleiben</span>
                  </label>
                </div>
                <div class="text-center">
                  <button
                    click="submit"
                    [disabled]="disableButton"
                    class="btn btn-primary my-4"
                    style="background: #d2db63; border-color: #d2db63"
                  >
                    Anmelden
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
