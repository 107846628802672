import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ConferencesService } from "./conferences.service";
import { Router } from "@angular/router";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-conferences",
  templateUrl: "./conferences.component.html",
  styleUrls: ["./conferences.component.scss"],
  providers: [ConferencesService],
})
export class ConferencesComponent implements OnInit {
  @ViewChild("classic2")
  private classic2: TemplateRef<any>;
  conferences = [];
  actualPage = 1;
  totalPages = [];
  user: string;
  closeResult: string;
  selectedConference: string;
  action: string;
  DELETE = "delete";
  EDIT = "edit";
  numberMaxPages: number;
  PAGES = 5;
  isNotEditable = false;

  constructor(
    private modalService: NgbModal,
    private conferencesService: ConferencesService,
    private router: Router
  ) {}

  ngOnInit() {
    if (
      localStorage.getItem("x-t-security") == undefined ||
      localStorage.getItem("user") == undefined
    ) {
      this.logout();
    } else {
      this.user = localStorage.getItem("user");
      this.conferencesService
        .getConferences(this.user, this.actualPage)
        .subscribe(
          (res) => {
            this.conferences = res.data.data_list;
            this.actualPage = res.data.actual_page;
            this.totalPages = Array.from(
              Array(Math.ceil(res.data.total_count / 10)).keys()
            );
            this.numberMaxPages = Math.ceil(res.data.total_count / 10);
            if (this.numberMaxPages > this.PAGES) {
              this.totalPages = Array.from(Array(5).keys());
            }
          },
          (err) => {}
        );
    }
  }

  getNewPage(page, direction) {
    this.conferencesService.getConferences(this.user, page).subscribe(
      (res) => {
        this.conferences = res.data.data_list;
        this.actualPage = res.data.actual_page;
        this.numberMaxPages = Math.ceil(res.data.total_count / 10);
        if (this.actualPage > this.PAGES && direction === "asc") {
          this.totalPages.push(page - 1);
          this.totalPages.shift();
        }
        if (
          this.totalPages.indexOf(this.actualPage) === 0 &&
          direction === "desc"
        ) {
          this.totalPages.unshift(page - 1);
          this.totalPages.pop();
        }
        if (direction === "last" && this.numberMaxPages > 5) {
          this.totalPages = [page - 5, page - 4, page - 3, page - 2, page - 1];
        }
        if (
          direction === "first" &&
          this.numberMaxPages > this.PAGES &&
          this.actualPage < this.PAGES
        ) {
          this.totalPages = [0, 1, 2, 3, 4];
        }
      },
      (err) => {}
    );
  }
  deleteConefrence(conferenceId) {
    this.conferencesService.deleteConferenceByNumber(conferenceId).subscribe(
      (res) => {
        this.getNewPage(this.actualPage, "");
      },
      (err) => {}
    );
  }
  editConefrence(conferenceId) {
    localStorage.setItem("editConference", conferenceId);
    this.router.navigate(["/edit-conference"]);
  }

  createConference() {
    this.router.navigate(["/create-conference"]);
  }

  open(content, type, modalDimension, conferenceId, action) {
    this.isNotEditable = false;
    this.selectedConference = conferenceId;
    this.action = action;
    if (modalDimension === "" && type === "Notification") {
      this.modalService
        .open(content, { windowClass: "modal-danger", centered: true })
        .result.then(
          (result) => {
            this.closeResult = `Closed with: ${result}`;
            if (action === this.DELETE) {
              this.deleteConefrence(conferenceId);
            }
            if (action === this.EDIT) {
              this.conferencesService.isEditable(conferenceId).subscribe(
                (res) => {
                  this.editConefrence(conferenceId);
                },
                (err) => {
                  this.isNotEditable = true;
                  this.open(
                    this.classic2,
                    "Notification",
                    "",
                    conferenceId,
                    ""
                  );
                }
              );
            }
          },
          (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  logout() {
    localStorage.removeItem("x-t-security");
    localStorage.removeItem("user");
    this.router.navigate(["/login"]);
  }
}
