<main class="profile-page" *ngIf="!isLoading">
  <section class="section-profile-cover section-shaped my-0">
    <!-- Circles background -->
    <div class="shape shape-style-1 shape-primary alpha-4">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  </section>
  <section class="section">
    <div class="container">
      <div class="card card-profile shadow mt--300">
        <div class="px-4">
          <div class="row">
            <div class="col-1 card-profile-actions py-4 mt-lg-0">
              <a href="javascript:void(0)" class="btn btn-sm btn-info mr-4" (click)="logout()">Abmelden</a>
            </div>
          </div>
          <div class="text-center mt-5">
            <h3 class="headline">Evaluation der Veranstaltung</h3>
            <div class="h6 font-weight-300"></div>
          </div>
          <div class="mt-5 py-5 border-top text-center" *ngIf="!successSending">
            <div class="">
              <form (ngSubmit)="
                  saveCourse(
                    title,
                    ort,
                    seminar_number,
                    workshops,
                    startTime,
                    endTime,
                    send_to,
                    konferenz
                  )
                ">
                <div class="row justify-content-center">
                  <div class="col-lg-10 col-9">
                    <label class="text-left d-block mb-1">Seminarnummer</label>
                    <input type="text" placeholder="Seminarnummer" name="Seminarnummer"
                      style="font-size: 16px;height: 50px" maxlength="150" class="form-control mb-1"
                      [(ngModel)]="seminar_number"
                      [ngStyle]="{'border': (errorSending && !seminar_number) ? '1px solid red' : null}" />
                    <small class="text-left d-block mb-5 text-muted"></small>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-lg-10 col-9">
                    <label class="text-left d-block mb-1">Veranstaltungstitel</label>
                    <input type="text" placeholder="Veranstaltungstitel" name="tagungstitel" maxlength="150"
                      style="font-size: 16px;height: 50px" class="form-control mb-1" [(ngModel)]="title"
                      [ngStyle]="{'border': (errorSending && !title) ? '1px solid red' : null}" />
                    <small class="text-left d-block mb-5 text-muted">Sichtbar im Deckblatt</small>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-lg-10 col-9">
                    <label class="text-left d-block mb-1">Ort</label>
                    <input type="text" placeholder="Ort" name="ort" maxlength="55" style="font-size: 16px;height: 50px"
                      class="form-control mb-1" [(ngModel)]="ort"
                      [ngStyle]="{'border': (errorSending && !ort) ? '1px solid red' : null}" />
                    <small class="text-left d-block mb-5 text-muted">Sichtbar im Deckblatt</small>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-lg-10 col-9 text-left">
                    <div class="custom-control custom-control-alternative custom-checkbox" style="margin-bottom: 25px;">
                      <input class="custom-control-input mb-1" id="olnKonferenz" type="checkbox" name="konferenz"
                        [(ngModel)]="konferenz" />
                      <label class="custom-control-label" for="olnKonferenz">
                        <span>Online Konferenz</span>
                      </label>
                    </div>

                  </div>

                </div>
                <div class="row justify-content-center">
                  <div class="col-lg-10 col-9" *ngIf="!isIos">
                    <label class="text-left d-block mb-1">Start- und Enddatum der Veranstaltung</label>
                    <ngbd-datepicker-i18n [datesPassed]="seminarDates" (sendDatesEvent)="getDates($event)" [errorSending]="errorSending">
                    </ngbd-datepicker-i18n>
                    <small class="text-left d-block mb-5 text-muted">Sichtbar im Deckblatt</small>
                  </div>
                  <div class="col-lg-10 col-9" *ngIf="isIos">
                    <div class="row">
                      <small class="text-left d-block font-weight-bold mb-1">Startdatum</small>
                      <div class="col-12">
                        <input type="date" placeholder="Start" name="dateFrom" style="font-size: 16px"
                          (change)="changeDateFrom($event)" class="form-control form-control-alternative mb-1"
                          [(ngModel)]="dateFrom" />
                      </div>
                    </div>
                    <div class="row">
                      <small class="text-left d-block font-weight-bold mb-1">Enddatum</small>
                      <div class="col-12">
                        <input type="date" placeholder="Ende" name="dateEnd" (change)="changeDateEnd($event)"
                          style="font-size: 16px" class="form-control form-control-alternative mb-5"
                          [(ngModel)]="dateEnd" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center">
                  <div class="col-lg-10 col-9">
                    <label class="text-left d-block mb-1">Workshops</label>

                    <section class="container border mb-4 p-3" *ngFor="let workshop of workshops; let j = index">
                      <div class="row">
                        <div class="col-6">
                          <h6>Workshop {{ j + 1 }}</h6>
                        </div>
                        <div class="col-6 text-right">
                          <button type="button" class="btn btn-danger btn-sm" (click)="removeWorkshop(j)">
                            Löschen
                          </button>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group">
                            <input type="text" class="form-control" [(ngModel)]="workshop.workshop" maxlength="100"
                              (keypress)="omit_special_char($event)" name="workshop_{{ j }}" placeholder="Titel" [ngStyle]="{'border': (errorSending && !workshop.workshop) ? '1px solid red' : null}"/>
                            <small class="text-left d-block text-muted">Maximal 100 Zeichen</small>
                          </div>
                        </div>
                      </div>
                    </section>
                    <button type="button" class="btn btn-primary mb-5" (click)="addWorkshop()"
                      [disabled]="total_workshops">
                      neuer Workshop
                    </button>
                  </div>
                </div>
                <div class="row justify-content-center mt-3" *ngIf="!isIos">
                  <div class="col-lg-10 col-9">
                    <label class="text-left d-block mt-1">Evaluationsstart und Evaluationsende</label>
                    <ngbd-datepicker-i18n (sendDatesEvent)="getEvaluationDates($event)"
                      [evaluationDatesPassed]="evaluationDates" [errorSending]="errorSending"></ngbd-datepicker-i18n>
                    <div class="row">
                      <div class="col-4">
                        <ngb-timepicker [(ngModel)]="startTime" name="start-time"></ngb-timepicker>
                      </div>
                      <div class="col-2"></div>
                      <div class="col-6">
                        <ngb-timepicker [(ngModel)]="endTime" name="ende-time"></ngb-timepicker>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center" *ngIf="isIos">
                  <div class="col-lg-10 col-9">
                    <div class="row">
                      <small class="text-left d-block font-weight-bold mb-3  mt-3">Evaluationsstart</small>
                      <div class="col-12">
                        <input type="date" placeholder="Evaluation Start" name="dateEvaluationFrom"
                          (change)="changeDateEvaluationFrom($event)" style="font-size: 16px"
                          class="form-control form-control-alternative mb-5" [(ngModel)]="dateEvaluationFrom" />
                      </div>
                      <div class="row">
                        <div class="col-12" style="margin-left: 30%; margin-top: -20%">
                          <ngb-timepicker [(ngModel)]="startTime" name="start-time"></ngb-timepicker>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <small class="text-left d-block font-weight-bold mb-3  mt-3">Evaluationsende</small>
                      <div class="col-12">
                        <input type="date" placeholder="Evaluation Ende" name="dateEvaluationEnd"
                          (change)="changeDateEvaluationEnd($event)" style="font-size: 16px" class="form-control mb-5"
                          [(ngModel)]="dateEvaluationEnd" />
                      </div>
                      <div class="row">
                        <div class="col-12" style="margin-left: 30%; margin-top: -20%">
                          <ngb-timepicker [(ngModel)]="startTime" name="start-time"></ngb-timepicker>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center mt-3">
                  <div class="col-lg-10 col-9">
                    <label class="text-left d-block mb-1">Auswertung E-Mail</label>
                    <input type="email" placeholder="E-Mail" name="email" maxlength="55"
                      style="font-size: 16px;height: 50px" class="form-control mb-1" [(ngModel)]="send_to"
                      [ngStyle]="{'border': (errorSending && !send_to) ? '1px solid red' : null}" />
                    <small class="text-left d-block mb-5 text-muted">Auswertung wird an diese E-Mail Adresse
                      versandt</small>
                  </div>
                </div>
                <div *ngIf="errorSending">
                  <app-alerts-section [customMessage]="ErrorMessage"></app-alerts-section>
                </div>
                <div>
                  <button class="btn btn-1 btn-validtor mt-3" [disabled]="disableSend" click="submit">
                    Weiter
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>