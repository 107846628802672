import { TopicModel } from "./topic.model";
import { VerdiService } from "./verdi.service";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-vote",
  templateUrl: "./vote.component.html",
  providers: [VerdiService],
  styleUrls: ["./vote.component.scss"],
})
export class VoteComponent implements OnInit {
  topics: TopicModel[] = [];
  courseId: Number;
  disableSend = false;
  myRecaptcha: boolean;
  questionnaireCode: string;
  showSubmit = false;
  showTos = false;
  showQuestions = false;
  showValidation = true;
  total = 0;
  unavailable = false;
  loading = false;
  showTermin = false;
  showAlreadyAnswered = false;
  code = "";
  path_code_course = "";
  workshops_professors: any;
  workshops = [];
  end_date: string;
  courseType: string;

  constructor(private verdiService: VerdiService, private router: Router) {}

  ngOnInit() {
    this.path_code_course = this.router.url.substr(1);
    if (this.path_code_course !== "") {
      this.code = this.path_code_course;
      if (this.code.length > 2) {
        this.showSubmit = true;
      }
    }
  }

  onHuman(resultCaptcha, questionnaireCode) {
    this.loading = true;
    // localStorage.clear();
    if (localStorage.getItem(questionnaireCode) !== null) {
      this.showValidation = false;
      this.showAlreadyAnswered = true;
    } else {
      this.showValidation = false;
      this.questionnaireCode = questionnaireCode;
      this.verdiService.getQuestionnaire(questionnaireCode).subscribe(
        (res: any) => {
          this.workshops_professors = res.data.workshops || [];
          this.end_date = res.data.end;
          this.courseType = res.data.course_type;
          this.courseId = res.data.course_id;

          for (let i = 0; i < this.workshops_professors.length; i++) {
            this.workshops.push({
              id: i,
              name: this.workshops_professors[i],
              collapse: true,
              topic: null,
            });
          }
          this.topics = res.data.details as any;
          for (let topic of this.topics) {
            topic.message = "";
            for (let question of topic.topic_wise_questions) {
              for (let choice of question.choices) {
                choice.checked = false;
              }
            }
          }
          this.loading = false;
          this.showTos = true;
          this.unavailable = false;
        },
        (err) => {
          this.loading = false;
          this.unavailable = true;
        }
      );
    }
  }
  keyPress(event: any) {
    if (event.target.value.length > 2) {
      this.showSubmit = true;
    }
    if (event.target.value.length <= 2) {
      this.showSubmit = false;
    }
  }
  showQu() {
    this.showTos = false;
    this.showQuestions = true;
    window.scrollTo(0, 0);
  }
  sendAnswers() {
    // this.disableSend = true;
    const data = {
      course: [],
    };
    for (let topic of this.topics) {
      if (topic.type === "hidden_till_choose") {
        if (topic.answer == undefined) {
          topic.answer = "Jonas Senftleben";
        }
      } else {
        topic.answer = "";
      }
      if (topic.type === "collapse") {
        for (let workshop of this.workshops) {
          let questionsList = [];
          if (workshop.topic != null) {
            for (let question of workshop.topic.topic_wise_questions) {
              for (let choice of question.choices) {
                if (choice.checked == true) {
                  questionsList.push({
                    question_id: question.question.question_id,
                    choice_id: choice.id,
                    topic_question_id: question.topic_wise_question_id,
                  });
                }
              }
            }
            data.course.push({
              topic_id: workshop.topic.topic.topic_id,
              topic_workshop: topic.answer,
              topic_workshop_name: workshop.name,
              course_topic_id: workshop.topic.course_topic_id,
              form: workshop.topic.message,
              questions: questionsList,
            });
          }
        }
        continue;
      }
      let questionsList = [];
      for (let question of topic.topic_wise_questions) {
        for (let choice of question.choices) {
          if (choice.checked == true) {
            questionsList.push({
              question_id: question.question.question_id,
              choice_id: choice.id,
              topic_question_id: question.topic_wise_question_id,
            });
          }
        }
      }
      data.course.push({
        topic_id: topic.topic.topic_id,
        topic_workshop: topic.answer,
        topic_workshop_name: "",
        course_topic_id: topic.course_topic_id,
        form: topic.message,
        questions: questionsList,
      });
    }
    let submitData = {
      course_number: this.questionnaireCode,
      end_date: this.end_date,
      ...data,
    };
    this.verdiService.sendQuestionnaireAnswers(submitData).subscribe(
      (res) => {
        this.terminateSession();
        this.disableSend = false;
      },
      (err) => {}
    );
  }

  onFilterChange(choices_list, choosen) {
    for (let choice of choices_list) {
      if (choice != choosen) {
        choice.checked = false;
      }
    }
  }

  terminateSession() {
    this.showQuestions = false;
    this.showTermin = true;
    localStorage.setItem(this.questionnaireCode, "verdi sucess");
  }
  exit() {
    window.location.reload();
  }
  collapseTab(workshop, topic) {
    if (workshop.collapse) {
      workshop.collapse = false;
    } else {
      workshop.collapse = true;
    }
    if (workshop.topic === null) {
      workshop.topic = JSON.parse(JSON.stringify(topic));
      for (let question of workshop.topic.topic_wise_questions) {
        for (let choice of question.choices) {
          choice.checked = false;
        }
      }
    }
  }
}
