<app-navbar></app-navbar>
<router-outlet *ngIf="!isIE"></router-outlet>
<div class="box" style="margin: 23% 0 0 0;" *ngIf="isIE">
  <div class="box__ghost">
    <div class="symbol"></div>
    <div class="symbol"></div>
    <div class="symbol"></div>
    <div class="symbol"></div>
    <div class="symbol"></div>
    <div class="symbol"></div>

    <div class="box__ghost-container">
      <div class="box__ghost-eyes">
        <div class="box__eye-left"></div>
        <div class="box__eye-right"></div>
      </div>
      <div class="box__ghost-bottom">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  </div>

  <div class="box__description">
    <div class="box__description-container">
      <div class="box__description-title"></div>
      <div class="box__description-text">
        <p style="align-self: center;">ver.di b+b Fragebogen wird wahrscheinlich nicht gut im Internet Explorer
          funktionieren. Wir unterst&uuml;tzen im allgemeinen nur die aktuelleren Versionen der wichtigsten
          Browser wie
          <a href="https://www.google.com/intl/de_de/chrome/">Google Chrome</a>,
          <a href="https://www.mozilla.org/de/firefox">Firefox</a>,
          <a href="https://www.apple.com/de/safari/">Safari</a>,
          <a href="https://www.opera.com/de">Opera</a> und
          <a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a>.
        </p>
      </div>
    </div>
  </div>

</div>

<app-footer *ngIf="!isIE"></app-footer>