<footer
  class="footer"
  [ngClass]="{
    'has-cards':
      getPath() !== '/user-profile' &&
      getPath() !== '/register' &&
      getPath() !== '/login'
  }"
>
  <div class="container">
    <hr />
    <div class="row align-items-center justify-content-md-between">
      <div class="col-md-6">
        <div class="copyright">
          &copy; {{ test | date: "yyyy" }}
          <a href="https://verdi-bub.de/" target="_blank"
            >ver.di b+b</a
          >
        </div>
      </div>
      <div class="col-md-6">
        <ul class="nav nav-footer justify-content-end">
          <li class="nav-item">
            <a
              href="https://verdi-bub.de/datenschutzerklaerung"
              class="nav-link"
              target="_blank"
              >Datenschutzerklärung</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
