import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./login/login.component";
import { AdminComponent } from "./admin/admin.component";
import { VoteComponent } from "./vote/vote.component";
import { ConferencesComponent } from "./conferences/conferences.component";
import { EditConferenceComponent } from "./edit-conference/edit-conference.component";
import { AuthGuard } from "./_helper/auth.guard";

const routes: Routes = [
  { path: "login", component: LoginComponent },
  {
    path: "conferences",
    component: ConferencesComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "edit-conference",
    component: EditConferenceComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "create-conference",
    component: AdminComponent,
    canActivate: [AuthGuard],
  },
  { path: "**", component: VoteComponent },
];

@NgModule({
  imports: [CommonModule, BrowserModule, RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
