import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { RouterModule } from "@angular/router";
import { AppRoutingModule } from "./app-routing.module";

import { AppComponent } from "./app.component";
import { RecaptchaModule } from "angular-google-recaptcha";

import { VoteComponent } from "./vote/vote.component";
import { LoginComponent } from "./login/login.component";
import { AdminComponent } from "./admin/admin.component";
import { ConferencesComponent } from "./conferences/conferences.component";
import { AlertsSectionComponent } from "./alerts-section/alerts-section.component";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { NavbarComponent } from "./shared/navbar/navbar.component";
import { FooterComponent } from "./shared/footer/footer.component";
import { NgbdDatepickerI18n } from "./datepicker-i18n";
import { HashLocationStrategy, LocationStrategy, registerLocaleData } from "@angular/common";
import localeFr from "@angular/common/locales/fr";

import { NgbDateCustomParserFormatter } from "./NgbDateCustomParserFormatter.service";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { EditConferenceComponent } from "./edit-conference/edit-conference.component";
import { JwtInterceptor } from "./_helper/jwt.interceptor";

registerLocaleData(localeFr, "fr");

@NgModule({
  declarations: [
    AdminComponent,
    AlertsSectionComponent,
    AppComponent,
    ConferencesComponent,
    EditConferenceComponent,
    FooterComponent,
    LoginComponent,
    NavbarComponent,
    NgbdDatepickerI18n,
    VoteComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NgbModule,
    ReactiveFormsModule,
    RouterModule,
    RecaptchaModule.forRoot({
      siteKey: "6LdXtLsUAAAAAI3hPr0CmeS5_0v32mJffaVgvQcm",
    }),
  ],
  providers: [
    // {
    //   provide: LocationStrategy,
    //   useClass: HashLocationStrategy,
    // },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
